import PropTypes from 'prop-types';
import { RenderInBody, Link } from 'bv-components';
import { t } from 'bv-i18n';
import TimeContainer from './components/time_container';

const TimeOutBannerView = ({ days, hours, minutes }) => (
  <RenderInBody>
    <div className="time-out-banner__wrapper">
      <Link to="/safer_gambling/portal/time_out">
        <div className="time-out-banner">
          <div className="time-out-banner__title">
            <i className="time-out-banner__icon" />
            {t('time_out_banner.time_out_remaining')}
          </div>
          <div className="time-out-banner__countdown-container">
            <TimeContainer time={days} title={t('time_out_banner.days')} />
            <TimeContainer time={hours} title={t('time_out_banner.hours')} />
            <TimeContainer time={minutes} title={t('time_out_banner.minutes')} />
          </div>
        </div>
      </Link>
    </div>
  </RenderInBody>
);

TimeOutBannerView.propTypes = {
  days: PropTypes.string.isRequired,
  hours: PropTypes.string.isRequired,
  minutes: PropTypes.string.isRequired,
};

export default TimeOutBannerView;

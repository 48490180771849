import PropTypes from 'prop-types';

const TimeContainer = ({ time, title }) => (
  <div className="time-out-banner__time-container">
    <div className="time-out-banner__time">
      {time}
    </div>
    <div className="time-out-banner__title">
      {title}
    </div>
  </div>
);

TimeContainer.propTypes = {
  time: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default TimeContainer;

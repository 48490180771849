import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TimeOutBannerView from './time_out_banner_view';

const dateDiff = (date1, date2) => {
  const diffInMilisec = date1 - date2;
  let minutes = Math.floor((diffInMilisec / (1000 * 60)) % 60);
  let hours = Math.floor((diffInMilisec / (1000 * 3600)) % 24);
  let days = Math.floor(diffInMilisec / (1000 * 3600 * 24));

  if (minutes < 0) minutes = 0;
  if (hours < 0) hours = 0;
  if (days < 0) days = 0;
  return {
    days: days.toString().padStart(2, '0'),
    hours: hours.toString().padStart(2, '0'),
    minutes: minutes.toString().padStart(2, '0'),
  };
};

const TimeOutBannerContainer = ({ expirationDate }) => {
  const [loading, setLoading] = useState(true);
  const [timeRemaining, setTimeRemaining] = useState({ days: '0', hours: '0', minutes: '0' });

  useEffect(() => {
    setTimeRemaining(dateDiff(expirationDate, new Date(Date.now())));
    const timer = setInterval(() => {
      setTimeRemaining(dateDiff(expirationDate, new Date(Date.now())));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (expirationDate > new Date(Date.now())) {
      document.body.classList.add('site-time-out-active');
      setLoading(false);
    }
    return () => { document.body.classList.remove('site-time-out-active'); };
  });

  if (loading) return null;
  return <TimeOutBannerView {...timeRemaining} />;
};

TimeOutBannerContainer.propTypes = {
  expirationDate: PropTypes.instanceOf(Date).isRequired,
};

export default TimeOutBannerContainer;
